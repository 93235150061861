import React, { useState, useEffect, useRef } from 'react';
import * as styles from "./PolicyList.module.scss"
import styled from 'styled-components'

const policyItems = [
  { "num": "01", "ttl": "組織の基盤", "text": "「安全・安心」こそ経営<br />基盤であり、守り続けます。" },
  { "num": "02", "ttl": "徹底したお客様<br />第一主義", "text": "お客様の期待を上回る商品・<br />サービスを提供していくために、<br /> お客様の声を大切にし、<br />お客様の視点で改善に努めます。" },
  { "num": "03", "ttl": "活力ある<br />企業風土の醸成", "text": "社員ひとり一人が明確な目標を持ち、<br />チャレンジ精神にあふれた<br />活力ある企業風土を創ります。" },
  { "num": "04", "ttl": "倫理行動", "text": "企業活動において、<br />社内規定、法令遵守の<br />精神を貫きます。" },
  { "num": "05", "ttl": "事業の発展", "text": "農業を通じて自然との調和を<br />図りながら事業を発展させ、<br />職員の夢の実現や地域社会への<br />貢献をしてまいります。" }
];

const PolicyList = () => {

  const target = useRef(null);

  const [classNames, setClassNames] = useState([styles.item, styles.scroll]);
  useEffect(() => {

    const targetTopPosition = target.current.getBoundingClientRect().top;
    const showTarget = () => {
      const TriggerMargin = window.innerHeight * 0.3;//ターゲット要素がWindowの下から30%の位置まで来たら

      const scrollPosition = window.scrollY + window.innerHeight;
      if (scrollPosition > targetTopPosition + TriggerMargin) {
        setClassNames([styles.item, styles.scrollShow])
      } else {
        setClassNames([styles.item, styles.scroll])
      }
    }
    showTarget();
    const onScroll = () => {
      showTarget();
    }
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <ol ref={target} className={styles.policyList + ' ' + classNames.join(" ")}>
      {policyItems.map((Item, i) => (
        <Li key={i} index={i}>
          <div className={styles.policyBox}>
            <div className={styles.policyBox__num}>{Item.num}</div>
            <div className={styles.policyBox__cont}>
              <h3 className={styles.policyBox__ttl} dangerouslySetInnerHTML={{ __html: Item.ttl }}></h3>
              <p className={styles.policyBox__text} dangerouslySetInnerHTML={{ __html: Item.text }}>
              </p>
            </div>
          </div>
        </Li>
      ))}
    </ol>
  )
}
export default PolicyList

const INTERVAL_SEC = 0.2;
// const transformDef = 50;
const Li = styled.li`
  opacity: 0;
  visibility: hidden;
  transform: translate(0, 50px);
  transition: all cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s;
  ${({ index }) => `
    transition-delay: ${INTERVAL_SEC * index}s;
  `}
`