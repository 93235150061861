import React, { useState, useEffect, useRef } from 'react';
import * as styles from "./Greeting.module.scss"
// import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"//ローカルから画像を持ってくる
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { SimpleAccordion } from '../../index'


const Greeting = () => {
  //ブレークポイント
  const breakpoints = useBreakpoint();
  //スクロールアニメーション
  const target = useRef(null);
  const [classNames, setClassNames] = useState([styles.item, styles.scroll]);

  useEffect(() => {
    //--- スクロールアニメーション
    const targetTopPosition = target.current.getBoundingClientRect().top;
    const showTarget = () => {
      const TriggerMargin = window.innerHeight * 0.3;//ターゲット要素がWindowの下から30%の位置まで来たら

      const scrollPosition = window.scrollY + window.innerHeight;
      if (scrollPosition > targetTopPosition + TriggerMargin) {
        setClassNames([styles.item, styles.scrollShow])
      } else {
        setClassNames([styles.item, styles.scroll])
      }
    }
    showTarget();
    const onScroll = () => {
      showTarget();
    }
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll, { passive: true });
    //--- スクロールアニメーション

  }, []);

  // //クリックイベント
  // const BtnClick = (e) => {
  //   e.stopPropagation();
  //   e.preventDefault();
  //   console.log("clicked");
  //   var more = document.querySelector(".Greeting__cont__more");
  //   var btn = document.querySelector(".Greeting__cont__btn");
  //   var span = btn.children[0];
  //   //判定
  //   var result = more.classList.contains("show");
  //   if (result) {
  //     more.classList.remove("show");
  //     span.innerHTML = "続きを読む";
  //   } else {
  //     more.classList.add("show");
  //     span.innerHTML = "閉じる";
  //   }
  // }

  return (
    <>
      <div className={styles.Greeting__header}>
        <div ref={target} className={styles.Greeting__bgSlideIn + " " + classNames.join(" ")}></div>
        <div className={styles.Greeting__headerInner}>
          <div className={styles.Greeting__lead}>
            <p>
              歴史と信頼をベースに、<br />
              常に進化し続ける<br />
              組合づくりを目指します。
            </p>
          </div>
          <div className={styles.Greeting__img}>
            {
              breakpoints.sm ?
                <StaticImage
                  layout="fullWidth"
                  src="../../../images/companyoutline/companyoutline_img02-sp.png"
                  alt="business_header"
                />
                :
                <StaticImage
                  layout="fullWidth"
                  src="../../../images/companyoutline/companyoutline_img02.png"
                  alt="business_header"
                />

            }
            <div className={styles.Greeting__nameBox}>
              <div className={styles.Greeting__role}>代表理事組合長</div>
              <div className={styles.Greeting__name}>末永明典</div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.Greeting__cont}>
        <p>
          深川養鶏農業協同組合は、戦前に鶏卵生産農家が集まってスタートした歴史ある組合です。戦後の食料難の時期に動物性たんぱく質の供給を目的として、鶏肉生産に取り組みました。長門市は古くからかまぼこ生産が盛んで、鶏の餌となる魚のアラが安く潤沢に手に入るという環境も手伝って、この地で成長することができたのだと思います。
        </p>
        {
          breakpoints.sm ?
            <SimpleAccordion className={`Greeting__cont__more`} triger={`閉じる`} trigerShow={`続きを読む`}>
              <p>
                仕事の内容は大きく4つにわかれます。ブロイラーの生産、加工、菓子づくり、そしてそれらの販売です。つくって、加工して、売るという一連の流れを全て行うため、最近言われている「6次産業」の要素をもった組織です。
              </p>
              <p>
                組合員が出資して運営する農業協同組合ですから、地元とは深い絆で結ばれています。これが組織を運営する上での強みです。お互いの意見が届きやすいため、製品開発やサービスに素早く反映させることができます。もちろん私たちも地元の信頼に応える仕事をしていかなくてはなりません。かといって、保守的な組合では将来性がありません。幸い長門市は水産・農業・畜産業が揃っており、高品質な商品がたくさんあります。近年では、これまで培ってきた信頼と販売ルートをベースに、これら地元の商品を扱う「田舎の商社」的な組織作りにも取り組んでいます。求める人物像は、何ごとにもチャレンジしていく前向きで明るい雰囲気をもった人、そして、地域に根を下ろし、地域を愛してくれる人。常に新しいアイデアを出し合い、全員参加で組織と地域を盛り上げていきましょう。
              </p>
            </SimpleAccordion>
            :
            <>
              <p>
                仕事の内容は大きく4つにわかれます。ブロイラーの生産、加工、菓子づくり、そしてそれらの販売です。つくって、加工して、売るという一連の流れを全て行うため、最近言われている「6次産業」の要素をもった組織です。
              </p>
              <p>
                組合員が出資して運営する農業協同組合ですから、地元とは深い絆で結ばれています。これが組織を運営する上での強みです。お互いの意見が届きやすいため、製品開発やサービスに素早く反映させることができます。もちろん私たちも地元の信頼に応える仕事をしていかなくてはなりません。かといって、保守的な組合では将来性がありません。幸い長門市は水産・農業・畜産業が揃っており、高品質な商品がたくさんあります。近年では、これまで培ってきた信頼と販売ルートをベースに、これら地元の商品を扱う「田舎の商社」的な組織作りにも取り組んでいます。求める人物像は、何ごとにもチャレンジしていく前向きで明るい雰囲気をもった人、そして、地域に根を下ろし、地域を愛してくれる人。常に新しいアイデアを出し合い、全員参加で組織と地域を盛り上げていきましょう。
              </p>
            </>
        }
      </div>
    </>
  )
}
export default Greeting