import React from "react"
// import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"//ローカルから画像を持ってくる
import * as styles from "./Associate.module.scss";

const Items = [
  {
    "index": "0",
    "name": "協同食鳥株式会社",
    "address": "福岡県北九州市八幡西区馬場山 9-1",
    "establish": "昭和 38 年 2 月",
    "business": "食肉の加工・販売",
    "capital": "1000 万円",
    "url": "",
    "map": "https://goo.gl/maps/TqFpnoXLyc7uVPho7"
  },
  {
    "index": "1",
    "name": "有限会社長洲産業",
    "address": "山口県長門市東深川 500 番地",
    "establish": "昭和 58 年 5 月",
    "business": "貨物自動車運送業",
    "capital": "1000 万円",
    "url": "",
    "map": "https://goo.gl/maps/xEtnNkX4qQXCxtAs6"
  },
  {
    "index": "2",
    "name": "有限会社ふかわファーム",
    "address": "山口県長門市東深川 1859-1",
    "establish": "平成 13 年 4 月",
    "business": "養鶏及び農産物の生産",
    "capital": "300 万円",
    "url": "",
    "map": "https://goo.gl/maps/Z1bwCFTDcAZEumZf7"
  },
  {
    "index": "3",
    "name": "株式会社つのせ",
    "address": "大阪市住吉区長居西 1-5-1",
    "establish": "平成 11 年 10 月",
    "business": "菓子類の販売",
    "capital": "2000 万円",
    "url": "https://www.tsunose.co.jp/",
    "map": "https://goo.gl/maps/PY9H3bMDr4oMGgVF7"
  },
];

const Associate = () => {
  return (
    <div className={styles.Associate} >
      <ul>
        {Items.map((Item, i) => (
          <li className={`index` + Item.index} key={i} >
            <h3 className={styles.Associate__ttl}><span>{Item.name}</span></h3>
            <div className={styles.Associate__cont}>
              <div className={styles.Associate__contChld} >
                <div className={styles.Associate__text}>
                  <span>所在地: {Item.address}</span><br />
                  <span>設立: {Item.establish}</span><br />
                  <span>事業内容: {Item.business}</span><br />
                  <span>資本金: {Item.capital}</span>
                  {
                    Item.url !== "" ?
                      <>
                        <br /><span>ホームページ: <a href={Item.url} target="_blank" rel="noreferrer">{Item.url}</a></span>
                      </>
                      :
                      null
                  }
                </div>
                <div className={styles.Associate__map}>
                  <a href={Item.map} target="_blank" rel="noopener noreferrer">
                    <StaticImage
                      src="../../../images/companyoutline/icon_office.png"
                      alt="アイコン"
                      placeholder="blurred"
                    />
                  </a>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div >
  )
}
export default Associate