/**
 * companyoutline.jsx
 * 会社概要ページ
 */

// (1) import層
import React from "react"
// import { Link } from "gatsby"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"//ローカルから画像を持ってくる
import { Layout, FltShopBtn, ScrollComponent } from "../components/index"//サイト全体のコンポーネント
import { LowerHeader, LowerTitle, LowerHeaderBg } from "../components/LowerPages/index"
import { PolicyList, Greeting, Overview, Office, Access, Associate } from "../components/Companyoutline/index.jsx"
// import SlideInSection from "../components/SlideInSection/SlideInSection"
// import SlideInLeft from "../components/SlideInSection/SlideInLeft"
// import SlideInRight from "../components/SlideInSection/SlideInRight"
// import styled from "styled-components"
import "../styles/LowerPage.scss";
import "../styles/Companyoutline.scss"
import { useBreakpoint } from 'gatsby-plugin-breakpoints'


// (2) Types層

// (3) DOM層
const Companyoutline = () => {
  const title1 = [
    { "id": 0, "text": "c" },
    { "id": 1, "text": "o" },
    { "id": 2, "text": "m" },
    { "id": 3, "text": "p" },
    { "id": 4, "text": "a" },
    { "id": 5, "text": "n" },
    { "id": 6, "text": "y" }
  ];
  const breakpoints = useBreakpoint();
  return (
    <>
      <Layout className={`companyoutline`} pageName="company" title="会社案内">
        <Seo
          lang="ja"
          title={`会社案内`}
          description="弊社の基本情報やアクセスについて。"
        />
        <div className={`LowerHeadImg`}>
          {
            breakpoints.sm ?
              <StaticImage
                layout="fullWidth"
                src="../images/companyoutline/companyoutline_header-sp.png"
                alt="business_header"
              />
              :
              <StaticImage
                layout="fullWidth"
                src="../images/companyoutline/companyoutline_header.png"
                alt="business_header"
              />

          }
        </div>
        <div className={`LowerWrapper`}>
          <LowerHeader data={title1} title={`会社案内`}>
            <LowerHeaderBg />
          </LowerHeader>
          <div className={`LowerContent`}>
            <div className={`RadiusCircle`} ></div>
            <section className={`LowerSection section01`}>
              <LowerTitle subTitle={`philosophy`}>
                経営理念
              </LowerTitle>
              <ScrollComponent>
                <h3 className={`LowerLead__Title`}><span><strong>喜び</strong>を<strong>創造</strong>する</span></h3>
                <div className={`LowerLead__Img`}>
                  {
                    breakpoints.sm ?
                      <StaticImage
                        layout="fullWidth"
                        src="../images/companyoutline/companyoutline_img01-sp.png"
                        alt="business_header"
                      />
                      :
                      <StaticImage
                        layout="fullWidth"
                        src="../images/companyoutline/companyoutline_img01.png"
                        alt="business_header"
                      />
                  }
                </div>
                <div className={`LowerLead`}>
                  <p>
                    お客様に喜んでいただける<br />職員が喜んで働ける<br />
                    生産者が喜んで生産できる<br />喜びを持った人材を育成し、<br />
                    自然環境との調和を保ち<br />地域社会に貢献できる組織を創る
                  </p>
                </div>
              </ScrollComponent>
            </section>
            <section className={`LowerSection`}>
              <LowerTitle subTitle={`policy`}>
                経営方針
              </LowerTitle>
              <PolicyList />
            </section>
            <section className={`LowerSection`}>
              <LowerTitle subTitle={`greeting`}>
                ご挨拶
              </LowerTitle>
              <Greeting />
            </section>
            <section className={`LowerSection`}>
              <LowerTitle subTitle={`overview`}>
                会社概要
              </LowerTitle>
              <Overview />
            </section>
            <section id="access" className={`LowerSection`}>
              <LowerTitle subTitle={`access`}>
                本部アクセス
              </LowerTitle>
              <Access />
            </section>
            <section className={`LowerSection`}>
              <LowerTitle subTitle={`office`}>
                事業所一覧
              </LowerTitle>
              <Office />
            </section>
            <section className={`LowerSection`}>
              <LowerTitle subTitle={`associate`}>
                関連会社一覧
              </LowerTitle>
              <Associate />
            </section>
          </div>
        </div>
        <FltShopBtn />
      </Layout>
    </>
  )
}

export default Companyoutline

// (4) Style層

// (5) Container層
