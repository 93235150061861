// extracted by mini-css-extract-plugin
export var Greeting__header = "Greeting-module--Greeting__header--29V03";
export var Greeting__headerInner = "Greeting-module--Greeting__headerInner--3F0GE";
export var Greeting__bgSlideIn = "Greeting-module--Greeting__bgSlideIn--tfCEp";
export var Greeting__lead = "Greeting-module--Greeting__lead--32JaN";
export var Greeting__img = "Greeting-module--Greeting__img--2v_NH";
export var Greeting__nameBox = "Greeting-module--Greeting__nameBox--1M4oy";
export var Greeting__role = "Greeting-module--Greeting__role--3yCtS";
export var Greeting__name = "Greeting-module--Greeting__name--1sJgk";
export var Greeting__cont = "Greeting-module--Greeting__cont--2omK0";
export var item = "Greeting-module--item--KvG-V";
export var scroll = "Greeting-module--scroll--PoWCp";
export var scrollShow = "Greeting-module--scrollShow--APT55";