import React from "react"
// import styled from 'styled-components'
import * as styles from "./Overview.module.scss";

const Items = [
  { "heading": "商号", "content": "深川養鶏農業協同組合" },
  { "heading": "設立", "content": "昭和23年5月" },
  { "heading": "代表者", "content": "代表理事組合長 末永 明典" },
  { "heading": "役員数", "content": "理事6名 監事3名" },
  { "heading": "組合員数", "content": "139名（正組合員42名・准組合員97名）" },
  { "heading": "出資金", "content": "273,080千円（2021年3月31日現在）" },
  { "heading": "事業内容", "content": "jigyou" },
  { "heading": "登録認証", "content": "touroku" },
  { "heading": "従業員数", "content": "415名" },
  { "heading": "取引銀行", "content": "banks" },
  { "heading": "本部所在地", "content": "〒759-4101 山口県長門市東深川1859-1" },
  { "heading": "TEL", "content": "0837-22-2121（代）" },
  { "heading": "FAX", "content": "0837-22-5432" },
  { "heading": "WEBサイト", "content": "www.chosyudori.or.jp" }
];

const Overview = () => {
  return (
    <dl className={styles.OverviewTable} >
      {Items.map((Item, i) => (
        <div className={styles.div} key={i} index={i}>
          <dt className={styles.dt}><span>{Item.heading}</span></dt>
          {
            Item.content === "jigyou" ?
              <dd className={styles.dd}>
                総合食品製造業チキンの生産・処理・販売<br />
                養鶏用飼料・資材・器具・薬品・ヒナ等の販売<br />
                鶏卵肉の加工食品、冷凍食品の製造・販売<br />
                菓子類の製造・販売<br />
                肥料の製造販売
              </dd>
              : Item.content === "touroku" ?
                <dd className={styles.dd}>
                  ISO9001:2015 2019.6.17取得<br />
                  HACCP CODE：2020 2017.7.11取得（ブロイラーセンター）<br />
                  JFS-B 2021.7.9取得（加工事業部 第一・第二工場）
                </dd>
                : Item.content === "banks" ?
                  <dd className={styles.dd}>
                    山口銀行　山口県信連　日本政策金融<br />
                    三菱UFJ　みずほ銀行　<br className={`smBr`} />福岡銀行　三井住友　<br className={`smBr`} />萩山口信用
                  </dd>
                  :
                  <dd className={styles.dd}>{Item.content}</dd>
          }
        </div>
      ))}
    </dl>
  )
}
export default Overview