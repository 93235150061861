import React from "react"
// import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"//ローカルから画像を持ってくる
import * as styles from "./Office.module.scss";

const Items = [
  { "group": "0", "groupName": "", "member": "1", "name": "本部", "address": "〒759-4101 山口県長門市東深川1859-1", "tel": "(0837)22-2121", "fax": "22-5432", "map": "https://goo.gl/maps/4RrVqftLqKN2" },
  { "group": "1", "groupName": "", "member": "1", "name": "ブロイラーセンター", "address": "〒759-4101 山口県長門市東深川505-1", "tel": "(0837)26-1218", "fax": "26-3122", "map": "https://goo.gl/maps/UT5kS5TaZEA2" },
  { "group": "3", "groupName": "", "member": "1", "name": "山口センター", "address": "〒759-4106 山口県長門市仙崎303", "tel": "(0837)22-2124", "fax": "22-6288", "map": "https://goo.gl/maps/fXwiDzmQdo52" },
  { "group": "5", "groupName": "", "member": "1", "name": "孵卵場", "address": "〒759-4102 山口県長門市西深川581-1", "tel": "(0837)22-5060", "fax": "22-2846", "map": "https://goo.gl/maps/ytyEi4FZ9JU2" },
  // { "group": "6", "groupName": "", "member": "1", "name": "大内山農場", "address": "〒759-4401 山口県長門市日置上2434-2", "tel": "(0837)37-3159", "fax": "", "map": "https://goo.gl/maps/wCxWtcQwcaG2" },
  // { "group": "7", "groupName": "", "member": "1", "name": "美東農場", "address": "〒754-0213 山口県美祢市美東町長登214-5", "tel": "(08396)2-1213", "fax": "", "map": "https://goo.gl/maps/1P6b7dJZqvP2" },
  { "group": "8", "groupName": "", "member": "1", "name": "小郡センター", "address": "〒754-0012 山口県山口市小郡船倉町3-8", "tel": "(083)972-2321", "fax": "973-4133", "map": "https://goo.gl/maps/YvX7bGYZKo92" },
  { "group": "9", "groupName": "", "member": "1", "name": "広島センター", "address": "〒739-0443 広島県廿日市市沖塩屋4-4-51", "tel": "(0829)50-4711", "fax": "55-1168", "map": "https://goo.gl/maps/AnbXKqXy1xA2" },
  { "group": "10", "groupName": "", "member": "1", "name": "松江センター", "address": "〒690-0017 島根県松江市西津田7-15-18", "tel": "(0852)26-0722", "fax": "27-6988", "map": "https://goo.gl/maps/dZYgBkB7PNL2" },
];

const Items1 = [
  { "group": "6", "groupName": "種鶏場", "member": "1", "name": "大内山農場", "address": "〒759-4401 山口県長門市日置上2434-2", "tel": "(0837)37-3159", "fax": "", "map": "https://goo.gl/maps/wCxWtcQwcaG2" },
  { "group": "6", "groupName": "種鶏場", "member": "1", "name": "美東農場", "address": "〒754-0213 山口県美祢市美東町長登214-5", "tel": "(08396)2-1213", "fax": "", "map": "https://goo.gl/maps/1P6b7dJZqvP2" },
]

const Items2 = [
  { "group": "4", "groupName": "製菓工場", "member": "2", "name": "第一工場", "address": "〒759-4101 山口県長門市東深川707-1", "tel": "(0837)22-2123", "fax": "22-8180", "map": "https://goo.gl/maps/ujtJsZvsaaQ2" },
  { "group": "4", "groupName": "製菓工場", "member": "2", "name": "第二工場", "address": "〒759-4101 山口県長門市東深川706", "tel": "(0837)22-1199", "fax": "22-1288", "map": "https://goo.gl/maps/6mi6HQzCkYR2" },
]

const Items3 = [
  { "group": "11", "groupName": "堆肥工場", "member": "2", "name": "油谷工場", "address": "〒759-4713 山口県長門市油谷角山262", "tel": "(0837)32-2781", "fax": "32-2084", "map": "https://goo.gl/maps/WUQ2ux4qRxz" },
  { "group": "11", "groupName": "堆肥工場", "member": "2", "name": "大峠工場", "address": "〒759-4104 山口県長門市渋木3034", "tel": "(0837)25-3271", "fax": "25-3272", "map": "https://goo.gl/maps/4pJx6rKTv2k" },
]

const Items4 = [
  { "group": "2", "groupName": "加工工場", "member": "3", "name": "第一工場（田屋工場）", "address": "〒759-4101 山口県長門市東深川505-1", "tel": "(0837)26-2991", "fax": "26-3715", "map": "https://goo.gl/maps/5XLaAxaHhso" },
  { "group": "2", "groupName": "加工工場", "member": "3", "name": "第二工場（丸山工場）", "address": "〒759-4101 山口県長門市東深川679-1", "tel": "(0837)26-5130", "fax": "26-5131", "map": "https://goo.gl/maps/nLRVPcjWcyF2" },
  { "group": "2", "groupName": "加工工場", "member": "3", "name": "営業課", "address": "〒759-4101 山口県長門市東深川679-1", "tel": "(0837)26-2424", "fax": "26-2425", "map": "https://goo.gl/maps/ZGE8YzeRi522" },
]


const Office = () => {
  return (
    <div className={styles.OfficeList} >
      <ul>
        {Items.map((Item, i) => (
          <li className={`group` + Item.group} key={i} >
            <h3 className={styles.OfficeList__ttl}><span>{Item.name}</span></h3>
            <div className={styles.OfficeList__cont}>
              <div className={styles.OfficeList__contChld} >
                <div className={styles.OfficeList__text}>
                  <span>{Item.address}</span><br />
                  {
                    Item.fax !== "" ?
                      <span>TEL：{Item.tel}　FAX：{Item.fax}</span>
                      :
                      <span>TEL・FAX：<br className={`smBr`} />{Item.tel}</span>
                  }
                </div>
                <div className={styles.OfficeList__map}>
                  <a href={Item.map} target="_blank" rel="noopener noreferrer">
                    <StaticImage
                      src="../../../images/companyoutline/icon_office.png"
                      alt="アイコン"
                      placeholder="blurred"
                    />
                  </a>
                </div>
              </div>
            </div>
          </li>
        ))}
        <li className={`group4`} >
          <h3 className={styles.OfficeList__ttl}><span>製菓工場</span></h3>
          <div className={styles.OfficeList__cont}>
            {Items2.map((Item, i) => (
              <div className={styles.OfficeList__contChld} key={i} >
                <div className={styles.OfficeList__text}>
                  <h4 className={styles.OfficeList__sttl}><span>{Item.name}</span></h4>
                  <span>{Item.address}</span><br />
                  {
                    Item.fax !== "" ?
                      <span>TEL：{Item.tel}　FAX：{Item.fax}</span>
                      :
                      <span>TEL・FAX：<br className={`smBr`} />{Item.tel}</span>
                  }
                </div>
                <div className={styles.OfficeList__map}>
                  <a href={Item.map} target="_blank" rel="noopener noreferrer">
                    <StaticImage
                      src="../../../images/companyoutline/icon_office.png"
                      alt="アイコン"
                      placeholder="blurred"
                    />
                  </a>
                </div>
              </div>
            ))}
          </div>
        </li>
        <li className={`group6`} >
          <h3 className={styles.OfficeList__ttl}><span>種鶏場</span></h3>
          <div className={styles.OfficeList__cont}>
            {Items1.map((Item, i) => (
              <div className={styles.OfficeList__contChld} key={i} >
                <div className={styles.OfficeList__text}>
                  <h4 className={styles.OfficeList__sttl}><span>{Item.name}</span></h4>
                  <span>{Item.address}</span><br />
                  {
                    Item.fax !== "" ?
                      <span>TEL：{Item.tel}　FAX：{Item.fax}</span>
                      :
                      <span>TEL・FAX：<br className={`smBr`} />{Item.tel}</span>
                  }
                </div>
                <div className={styles.OfficeList__map}>
                  <a href={Item.map} target="_blank" rel="noopener noreferrer">
                    <StaticImage
                      src="../../../images/companyoutline/icon_office.png"
                      alt="アイコン"
                      placeholder="blurred"
                    />
                  </a>
                </div>
              </div>
            ))}
          </div>
        </li>
        <li className={`group11`} >
          <h3 className={styles.OfficeList__ttl}><span>堆肥工場</span></h3>
          <div className={styles.OfficeList__cont}>
            {Items3.map((Item, i) => (
              <div className={styles.OfficeList__contChld} key={i} >
                <div className={styles.OfficeList__text}>
                  <h4 className={styles.OfficeList__sttl}><span>{Item.name}</span></h4>
                  <span>{Item.address}</span><br />
                  {
                    Item.fax !== "" ?
                      <span>TEL：{Item.tel}　FAX：{Item.fax}</span>
                      :
                      <span>TEL・FAX：<br className={`smBr`} />{Item.tel}</span>
                  }
                </div>
                <div className={styles.OfficeList__map}>
                  <a href={Item.map} target="_blank" rel="noopener noreferrer">
                    <StaticImage
                      src="../../../images/companyoutline/icon_office.png"
                      alt="アイコン"
                      placeholder="blurred"
                    />
                  </a>
                </div>
              </div>
            ))}
          </div>
        </li>
        <li className={`group2`} >
          <h3 className={styles.OfficeList__ttl}><span>加工工場</span></h3>
          <div className={styles.OfficeList__cont}>
            {Items4.map((Item, i) => (
              <div className={styles.OfficeList__contChld} key={i} >
                <div className={styles.OfficeList__text}>
                  <h4 className={styles.OfficeList__sttl}><span>{Item.name}</span></h4>
                  <span>{Item.address}</span><br />
                  {
                    Item.fax !== "" ?
                      <span>TEL：{Item.tel}　FAX：{Item.fax}</span>
                      :
                      <span>TEL・FAX：<br className={`smBr`} />{Item.tel}</span>
                  }
                </div>
                <div className={styles.OfficeList__map}>
                  <a href={Item.map} target="_blank" rel="noopener noreferrer">
                    <StaticImage
                      src="../../../images/companyoutline/icon_office.png"
                      alt="アイコン"
                      placeholder="blurred"
                    />
                  </a>
                </div>
              </div>
            ))}
          </div>
        </li>
      </ul>
    </div >
  )
}
export default Office