import React from "react";
import { StaticImage } from "gatsby-plugin-image"//ローカルから画像を持ってくる
import * as styles from "./Access.module.scss";

const Access = () => {
  return (
    <div className={styles.Access}>
      <div className={styles.Access__map}>
        <iframe frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?q=%E3%80%92759-4101%E5%B1%B1%E5%8F%A3%E7%9C%8C%E9%95%B7%E9%96%80%E5%B8%82%E6%9D%B1%E6%B7%B1%E5%B7%9D%E6%B7%B1%E5%B7%9D%E9%A4%8A%E9%B6%8F%E8%BE%B2%E6%A5%AD%E5%8D%94%E5%90%8C%E7%B5%84%E5%90%88%E6%9C%AC%E9%83%A8&amp;t=m&amp;z=17&amp;output=embed&amp;iwloc=near&amp;ll=34.367450868185195,131.183210051136" aria-label="〒759-4101山口県長門市東深川深川養鶏農業協同組合本部" title="深川養鶏農業協同組合本部"></iframe>
      </div>
      <div className={styles.Access__list}>
        <ul>
          <li>
            <StaticImage
              src="../../../images/companyoutline/icon_access1.png"
              alt="アイコン"
              placeholder="blurred"
            />
            <span>
              〒759-4101 山口県長門市東深川1859-1
            </span>
          </li>
          <li>
            <StaticImage
              src="../../../images/companyoutline/icon_access2.png"
              alt="アイコン"
              placeholder="blurred"
            />
            <a href="tel:0837222121"><span>0837-22-2121</span></a>
          </li>
          <li>
            <StaticImage
              src="../../../images/companyoutline/icon_access3.png"
              alt="アイコン"
              placeholder="blurred"
            />
            <span>
              0837-22-5432
            </span>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Access